import React, { useCallback, useEffect, useState } from 'react';

import Chip from '@rotaryintl/harris-chip';
import { FilterCategoryDisclosure } from '@rotaryintl/harris-filtering';
import { Form, Formik, FormikValues, useFormikContext } from 'formik';

import { Lang } from '@presenters/web/pages/Districts/ViewInvoices/ViewInvoices';

import { useTranslation } from '@external/react-i18next';

import { DIS } from '@typings/dis';

interface ChipDisplayProps {
  onFilterChange: (selectedFilters: string[]) => void;
  filterClubTypeOption: { checked: boolean; label: string; value: string }[];
}

const ChipDisplayComponent: React.FC<ChipDisplayProps> = ({
  filterClubTypeOption,
  onFilterChange,
}) => {
  const { values, setValues } = useFormikContext<FormikValues>();

  useEffect(() => {
    onFilterChange(values.clubType);
  }, [values.clubType]);

  const removeClubTypeOptionChip = useCallback(
    (val: string) => {
      setValues((prev: { clubType: string[] }) => ({
        ...prev,
        clubType: prev.clubType.filter(option => option !== val),
      }));
    },
    [setValues]
  );
  const selectedClubTypes = new Set(values.clubType);
  const matchingClubTypes = filterClubTypeOption.filter(option =>
    selectedClubTypes.has(option.value)
  );
  return (
    <div
      className={`flex flex-row gap-1 ${
        matchingClubTypes.length ? 'pt-6' : ''
      }`}
    >
      {matchingClubTypes.map(({ value, label }) => (
        <Chip
          key={value}
          label={label}
          onClick={() => removeClubTypeOptionChip(value)}
        />
      ))}
    </div>
  );
};

interface FilterClubTypeProps {
  onFilterChange: (selectedFilters: string[]) => void;
  languagePrefix: Lang;
}

export const FilterClubType: React.FC<FilterClubTypeProps> = ({
  onFilterChange,
  languagePrefix,
}) => {
  const { t } = useTranslation();
  const [filterClubTypeOption] = useState([
    { checked: false, label: 'Rotary', value: DIS.ClubTypeEnum.RotaryClub },
    { checked: false, label: 'Rotaract', value: DIS.ClubTypeEnum.RotaractClub },
  ]);
  return (
    <Formik
      initialValues={{
        clubType: [],
      }}
      validateOnChange
      onSubmit={() => {}}
    >
      {({ setValues }) => {
        return (
          <Form>
            <div className="flex flex-row gap-6">
              <div className="font-bold">
                {t('district-invoice.filters', 'Filters')}:
              </div>
              <div className="w-74">
                <FilterCategoryDisclosure
                  key={filterClubTypeOption.map(opt => opt.checked).join(',')}
                  filterCategoryChecklistProps={{
                    lang: languagePrefix,
                    name: 'clubType',
                    options: filterClubTypeOption,
                    showFilter: true,
                    showTitle: true,
                    title: t('district-invoice.club-type', 'Club type'),
                  }}
                />
              </div>
              <div
                className="ml-auto font-bold text-dblue cursor-pointer"
                onClick={() => setValues({ clubType: [] })}
              >
                {t('district-invoice.clear-all', 'Clear all')}
              </div>
            </div>
            <ChipDisplayComponent
              filterClubTypeOption={filterClubTypeOption}
              onFilterChange={onFilterChange}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
